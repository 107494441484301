<template>
  <el-dialog
    v-drag
    title="修改"
    :visible.sync="dialogFormVisible"
    width="510px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <el-form :model="form" class="form" label-position="top" label-width="80px">
      <el-form-item label="信用额度">
        <el-input
          v-model="form.creditAmount"
          placeholder="请输入信用额度"
          style="width: 320px"
        ></el-input>
      </el-form-item>
      <el-form-item label="修改原因">
        <el-select
          v-model="form.reason"
          filterable
          style="width: 320px"
          placeholder="请选择修改原因"
        >
          <el-option
            v-for="item in commOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="add" @click="add">管理原因</span>
      </el-form-item>
      <el-form-item label="状态" prop="isActived">
        <el-switch
          v-model="form.isActived"
          active-color="#2395F3"
          inactive-color="#f1f1f1"
        >
        </el-switch>
        <span class="lable">{{ form.isActived ? "启用" : "停用" }}</span>

      </el-form-item>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok">确 定</div>
    </span>
    <CommDialog
      :dialogVisible="dialogVisible2"
      :dataColumn="commOptions"
      title="原因"
      :type="9"
      @ok="cancel2"
      @cancel="cancel2"
      @dataChange="dataChange"
    ></CommDialog>
  </el-dialog>
</template>
<script>
import { getSysParams } from '@/api/system'
import { companyUpdate } from '@/api/client'
const CommDialog = () => import('@/components/dialog/CommDialog')
export default {
  components: { CommDialog },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        companyId: '',
        creditAmount: '',
        reason: '',
        isActived: true
      },
      commData: [],
      dialogVisible2: false,
      loading: false
    }
  },
  computed: {
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getCommData()
        this.form = {
          ...this.editData
        }
      }
    }
  },
  methods: {
    ok() {
      if (this.form.creditAmount === '' && !this.form.reason) {
        this.$message.warning('请填写信用额度和原因')
        return
      }
      this.loading = true
      companyUpdate({
        ...this.form
      }).then(res => {
        this.loading = false
        if (res.success) {
          this.$message.success('修改成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    add() {
      this.dialogVisible2 = true
    },
    getCommData() {
      getSysParams({ type: 9 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    dataChange() {
      this.getCommData()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 550px;
  display: flex;
  max-height: 550px;
  .form {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
  .add {
    width: 56px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    cursor: pointer;
  }
  .lable {
    width: 28px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    vertical-align: middle;
  }
  .footers {
    display: flex;
    justify-content: center;
    .cancel {
      width: 120px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
